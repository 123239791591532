@import "src/styles/_base.module.scss";
.form {
  position: relative;
  .formInnerWrapper {
    background: #fff;
    font-size: rem(16);
    width: 100%;
    line-height: rem(20);
    text-align: left;
  }

  .formActionsWrapper {
    position: absolute;
    text-align: right;
    width: 100%;
    margin-top: rem(20);
    :global {
      .form-elements {
        padding: 0;
        margin-left: rem(20);
      }
    }
  }
}

.fileInput {
  margin-bottom: rem(10);
  :global {
    .form-file {
      border-top: none;
      border-radius: rem(0 0 3 3);
    }
  }
}
.formColumns {
  clear: both;
  .formColumn2 {
    float: left;
    clear: none;
    text-align: inherit;
    width: 50%;
    margin-left: 0;
    margin-right: 3%;
  }
  .formColumn3 {
    float: left;
    clear: none;
    text-align: inherit;
    width: 33.3%;
    margin-left: 0;
    margin-right: 3%;
  }
  .formColumn4 {
    float: left;
    clear: none;
    text-align: inherit;
    width: 25%;
    margin-left: 0;
    margin-right: 3%;
  }
  .formColumn5 {
    float: left;
    clear: none;
    text-align: inherit;
    width: 20%;
    margin-left: 0;
    margin-right: 3%;
  }
  .formColumn6 {
    // @include col(1/6);
    float: left;
    clear: none;
    text-align: inherit;
    width: 16.3%;
    margin-left: 0;
    margin-right: 3%;
  }
}

.label {
  @include defaultLabelStyle;
  display: block;
  margin-bottom: rem(8);
  .description {
    @include defaultDescriptionStyle;
  }
}

.fieldset {
  border: none;
  margin: 0;
  padding: rem(40 40 20 40);
  @include to(s) {
    padding: rem(10);
  }
  .title {
    color: #2fa5fd;
  }
  .description {
    @include defaultDescriptionStyle;
  }
  .content {
    margin-top: rem(20);
  }
  :global {
    .form-elements {
      margin-bottom: rem(20);
    }
  }
}

.inputWrapper {
  display: block;
  position: relative;
  .inputInnerWrapper {
    display: block;
    .input {
      @include defaultInputStyle;
      width: 100%;
      display: block;
      &.inline {
        width: auto;
        display: inline-block;
      }
    }
    :global {
      .form-element {
        display: block;
      }
    }
  }
  &.selectWrapper {
    :global {
      .Select-control {
        border-color: color('light gray');
        height: rem(40);
        border-radius: rem(2);
      }

      .is-open > .Select-control {
        border-color: color('blue');
      }

      .is-open > .Select-menu-outer {
        margin-top: rem(2);
        border: 1px solid color('light gray');
        box-shadow: 0 rem(1) rem(15) rgba(#000, 0.05);
        border-radius: rem(3);
      }

      .Select-option {
        color: color('dark blue');
        font-size: rem(14);
        font-weight: 400;
      }

      .Select-clear {
        line-height: 2;
      }

      .Select-option.is-selected {
        background: transparent;
      }

      .Select-option.is-focused {
        background: color('light blue');
      }

      .Select-arrow {
        border-top-color: color('gray');
      }

      .is-open .Select-arrow {
        border-top-color: color('blue');
      }

      .Select-arrow-zone svg {
        color: color('gray');
      }

      .is-open .Select-arrow-zone svg {
        color: color('blue');
      }

      .Select-control:hover {
        box-shadow: none;
      }

      .Select-placeholder {
        color: color('gray');
        line-height: rem(37);
        font-size: rem(14);
      }

      .Select-placeholder,
      .Select--single > .Select-control .Select-value {
        line-height: rem(37);
        font-size: rem(14);
      }

      .Select-value,
      .Select-value-label {
        font-size: rem(14);
        color: color('dark gray') !important;
      }
      .is-focused:not(.is-open) > .Select-control {
        border-color: color('light gray') !important;
        box-shadow: none !important;
      }

      .Select--multi .Select-value {
        // @include cf;
        border: none;
        background: lighten(color('blue'), 30);
        padding: rem(2);
      }
      .Select--multi .Select-value-icon {
        float: right;
        border: none;
        padding: rem(5);
        line-height: rem(14);
        font-size: rem(22);
        &:hover {
          background: none;
          color: color('red');
        }
      }
      .Select--multi .Select-value-label {
        float: left;
      }
    }
  }
  .icon {
    position: absolute;
    display: block;
    padding: rem(9);
    width: rem(40);
    height: rem(40);
    top: 0;
    left: 0;
    svg {
      width: rem(20);
      height: rem(20);
      display: block;
      color: color('gray');
    }
  }
  .hasIcon input {
    padding-left: rem(40) !important;
  }
  .hasIcon.iconRight .icon {
    left: auto;
    right: 0;
  }
  .hasIcon.iconRight input {
    padding-left: rem(9) !important;
    padding-right: rem(40) !important;
  }
  .hasIcon.iconLeft .icon {
    right: auto;
    left: 0;
  }
  .hasIcon.iconLeft input {
    padding-right: rem(9) !important;
    padding-left: rem(40) !important;
  }
  &.inputFocused svg {
    color: color('blue');
  }
}

.imageUploadWrapper {
  .dropInnerWrapper {
    background: rgba(color('light gray'), 0.3);
    border: rem(1) dashed color('light gray');
    text-align: center;
    padding: rem(40);
    .dropzonePlaceholder {
      color: color('gray');
      margin-bottom: rem(20);
      svg {
        margin-bottom: rem(10);
      }
      p {
        color: color('gray');
        margin: 0;
        font-size: rem(14);
      }
    }
    :global {
      .form-elements,
      .form-element {
        margin-bottom: 0;
      }
    }
  }
}

.buttonWrapper {
  display: block;
  width: 100%;
  .nav {
    height: rem(30);
    margin-top: rem(14);
  }
  .square {
    padding: 0 !important;
    width: rem(30) !important;
    height: rem(30) !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .button {
    @include defaultButtonStyle;
    display: block;
    width: 100%;
    @include to(m) {
      font-size: 14px;
    }
    &.small {
      @include defaultButtonStyle('small');
    }
  }
  &.inline {
    display: inline-block;
    width: auto;
    .button {
      display: inline-block;
      width: auto;
      white-space: nowrap;
    }
  }
  .settingsMenu {
    color: color('dark blue') !important;
    cursor: pointer;
    display: block;
    -webkit-font-smoothing: antialiased;
    font-size: rem(14);
    font-weight: normal;
    text-align: left;
    &:hover {
      background: color('light blue');
      color: lighten(color('blue'), 5%);
    }
  }
  .cancel {
    color: color('blue');
    &:hover {
      color: lighten(color('blue'), 5%);
    }
  }
  .submit {
    &:hover:not([disabled]) {
      color: color('white') !important;
      background: lighten(color('blue'), 2%) !important;
    }
    color: color('white') !important;
    background: color('blue') !important;
  }
  .secondary {
    &:hover:not([disabled]) {
      background: lighten(color('light blue'), 2%) !important;
      color: color('blue');
    }
    background: color('light blue') !important;
    color: color('blue') !important;
  }
  .important {
    &:hover:not([disabled]) {
      background: lighten(color('green'), 2%) !important;
      color: white;
    }
    background: color('green') !important;
    color: white;
  }
  .regular {
    &:hover:not([disabled]) {
      background: lighten(color('light gray'), 2%) !important;
      color: color('dark gray') !important;
    }
    background: color('light gray') !important;
    color: color('dark gray') !important;
  }
  .white {
    &:hover:not([disabled]) {
      background: darken(color('white'), 2%) !important;
      color: color('gray');
    }
    background: color('white') !important;
    color: color('dark gray') !important;
    border: rem(1) solid color('light gray');
  }
  .blue {
    &:hover:not([disabled]) {
      background: darken(color('blue'), 2%) !important;
      color: color('white');
    }
    background: color('blue') !important;
    color: color('light gray') !important;
    border: none;
  }
    .red {
      &:hover:not([disabled]) {
        background-color: #FD3732 !important;
        color: color('white');
      }
  
      background: #FD3732 !important;
      color: color('light white') !important;
      border: none;
    }
}

.checkbox {
  display: none;
}

.inputCheckboxLabel {
  color: color('dark gray');
  font-weight: 300;
  position: relative;
  padding-left: rem(30);
  cursor: pointer;
  margin-bottom: rem(3);
  @include to(m) {
    display: flex !important;
    justify-content: space-between !important;
  }
  &:before {
    content: '';
    @include defaultInputStyle;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
  &.checked:after {
    content: '';
    position: absolute;
    width: rem(7);
    height: rem(16);
    top: rem(1);
    left: rem(7);
    border: solid color('blue');
    border-width: rem(0 2 2 0);
    transform: rotate(45deg);
  }
  &.checked {
    color: color('blue');
  }
  input {
    display: none;
  }
  strong {
    display: inline-block;
    padding-right: rem(5);
    font-size: rem(14);
    @include to(m) {
      font-size: rem(12);
    }
  }
  p {
    display: inline-block;
  }
}

.fieldLabelDescription {
  margin: 0;
  font-size: rem(14);
  color: color('gray');
  @include to(s) {
    font-size: rem(10) !important;
  }
}

.isUploading {
  opacity: 0.2 !important;
}

.uploadError {
  background: rgba(red, 0.1) !important;
}

.disabled {
  opacity: 0.5;
}

.fileUploadWrapper {
  border: rem(1) solid color('light gray');
  border-radius: rem(3);
  .dropInnerWrapper {
    padding: rem(10);
    color: color('dark gray');
    cursor: pointer;
    &:hover {
      color: color('blue');
    }
    :global {
      svg {
        vertical-align: middle !important;
      }
    }
  }
}

.fieldError {
  font-size: rem(12);
  color: color('red');
}

.fieldHasError {
  color: color('red');
  .fieldLabel {
    color: color('red');
  }
}

.acronymtWrapper {
  display: inline-block;
  vertical-align: top;

  // @include cf;
  .acronymInnerWrapper {
    width: rem(55);
    display: inline-block;
    input {
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
  .label {
    float: right;
    width: calc(100% - 65px);
    font-size: rem(14);
    color: color('gray');
  }
}

.pipelineSelectWrapper {
  height: 100%;
  .pipelineSelectInnerWrapper {
    float: left;
    width: 100%;
  }
}

.formTooltip {
  position: absolute;
  left: 100%;
  top: 0;
  text-align: left;
  color: color('gray');
  font-size: rem(14);
  width: rem(250);
  margin-left: rem(40);
  margin-top: rem(-45);
  border-left: rem(3) solid color('blue');
  padding-left: rem(20);
  line-height: 1.3;
  strong {
    font-weight: 500;
    color: color('gray');
  }
  p {
    margin: 0;
    font-size: rem(12);
  }
}

.colorWrapper {
  label {
    color: color('gray');
    font-size: rem(14);
  }
}

.info {
  color: color('light gray');
  font-size: rem(14);
  line-height: rem(18);
  svg {
    color: color('light gray');
  }
}

.input {
  @include defaultInputStyle;
}

.pipelineTemplateSelect {
  margin-bottom: 20px;

  .pipelineOrSpacer {
    color: color('gray');
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pipelineRenderField {
    display: inline-block;
    width: 280px;
    margin-top: 10px;
    position: relative;
  }
  .pipelineRenderFieldAddOn {
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 38px;
    border: 1px solid #dee1e4;
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
    background-color: #f6f8fa;
    text-align: center;
    vertical-align: middle;
    line-height: 38px;
    color: grey;
  }
  .pipelineRenderFieldInput {
    height: 38px;
    width: 85%;
    padding-left: 10px;
    margin-left: 40px;
  }
  .pipelineRenderFieldRemoveColumn {
    color: grey;
    margin-left: 2px;
    position: relative;
  }
  .pipelineRenderFieldCloseIcon {
    position: absolute;
    margin-left: 20px;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
  .pipelineAddInputButton {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #32a3fd;
    cursor: pointer;
  }
  .pipelineStagesLabel {
    color: grey;
    font-size: 12px;
  }
}
.navSearch {
  background-color: (rgba(0, 0, 0, 0.2)) !important;
  border: none !important;
  padding-right: 100px !important;
}

.positionLevelCheckbox {
  @include to(m) {
    display: flex;
    justify-content: space-between;
  }
}

.centerAlignButton {
  button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.jobIndexActionBtn {
  @include to(m) {
    button {
      font-size: 8px !important;
      padding: 4px 8px !important;
    }
  }
}

.jobColors {
  max-height: 29px;
}
.addPipelineButton {
  button {
    color: color('blue') !important;
    padding: 32px 0 !important;
    background: color('light blue') !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }
}
.description {
  @include defaultDescriptionStyle;
}
