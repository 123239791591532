@import "src/styles/_base.module.scss";
.roles {

  .rolesSection {
    background-color: #ffffff;
    padding: 10px;
    padding-bottom: 10px;
    box-shadow: 1px 1px 4px lightgrey;

    .search {
      width: 100%;
      background: white;
      border: 1px solid lightgrey;
      input {
        width: 100%;
        margin: 20px;
        padding: 10px;
        width: -webkit-fill-available;
      }
    }
  }
  .first {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: #fff;
    box-shadow: 1px 1px 4px lightgrey;


    h5{
      font-weight: 600;
      font-size: 14px;
      color: #2c3346;
      margin: 0;
      text-transform: capitalize;

    }
    @include to(s) {
      padding: 20px 10px;

      h5{
        font-size: 14px;
      }
      }
  }
  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #ffffff;
    color: #32a3fd;
    align-items: center;
    font-size:0.9rem;
    font-weight: bold;
    cursor: pointer;
    padding: 1.8rem;
    border-radius: 3px;
  }
  .btnWidth {
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .btnColor {
    background-color: #32a3fd;
    color: white;
  }
  .third {
    background: white;
    h4 {
      padding: 20px 0;
      align-items: center;
      width: 97%;
      margin: auto;
    }

    .second {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      border: 2px solid #dce0e3;
      align-items: center;
      background-color: #f6f8fa;
      justify-content: space-between;
      padding: 10px;
      border-bottom: none;

      .forth {
        width: 75%;
        margin: auto 0;
        align-items: center;
        p {
          margin: 0;
        }
        @include to(s) {
          h5{
            font-size: 13px;
          }
          p {
            font-size: 13px;
          }
        }
      }
      .switch {
        .slider {
          span {
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
          }
          .yes {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 11px;
          }
          .no {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 38px;
          }
        }

      }
    }
    .BorderNone{
      border-bottom: 2px solid #dce0e3;
    }
  }
}
