@import "src/styles/_base.module.scss";
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdownButton {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  font-size: 12px;
  margin-left: auto;
}

.dropdownMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  width: 100%;
  z-index: 1000;
}

.dropdownItem {
  position: relative; /* Ensures nestedDropdown aligns relative to this */
  padding: 10px;
  cursor: pointer;
  background: #f9f9f9;
  transition: background 0.3s ease;

  &:hover {
    background: #e0e0e0;
  }
}

.nested {
  position: relative; /* Critical: ensures the nested dropdown is relative to this item */
}

.nestedDropdown {
  display: none;
  position: absolute;
  left: 100%; /* Positions to the right of the parent item */
  top: 0;
  background: #fff;
  border: 1px solid #ccc;
  list-style: none;
  width: 200px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add some depth */
}

.dropdownItem:hover > .nestedDropdown {
  display: block; /* Show nested menu when parent is hovered */
}

.dropdownItem span {
  display: inline-block;
  width: 100%;
}
