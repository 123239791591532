@import "src/styles/_base.module.scss";
.clientFormContainer {
  width: 100%;
  margin: 0 auto;

  :global {
    .form-fields {
      margin-left: rem(362);
    }

    .form-fieldset {
      border-left: rem(1) solid color('light gray');

      &:last-child {
        border-top: rem(1) solid color('light gray');
      }
    }
  }

  textarea {
    height: rem(210);
  }
.action-footer {
  position: relative;

  .delete-button {
    float: left;
    background-color: #FD3732;
    color: #FFFFFF;
  }
}
}

.addressContainer {
  :global {
    .form-elements {
      margin-bottom: rem(5);
    }
  }
}

.country {
  :global {
    .form-column:first-child {
      width: 68.5%;
    }

    .form-column:last-child {
      width: 28.5%;
    }
  }
}

.descriptionWrapper {
  margin-bottom: 0.25rem;
}

.clientDescriptionLabel {
  font-weight: 500;
  color: #272e41;
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.5rem;
}

.clientDescription {
  line-height: 1;
  margin: 0.25rem 0 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: #dce0e3;
}